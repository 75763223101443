<template>
    <div id="report">
      <el-container style="display:block;">
        <el-row>
          <el-col :span="3">
            <span>检查阶段：</span>
          </el-col>
          <el-col :span="21">
            <el-badge 
              v-for="(_, patrolTimes) of community.patrolTimes" 
              :key="patrolTimes" 
              style="margin-right: 70px; margin-bottom: 20px">
              <el-button @click="patrolTimeClick(patrolTimes)" :type="(patrolTime==patrolTimes?'primary':'')"> {{ patrolTimes }}</el-button>
            </el-badge>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-row>
              <table style="width: 100%;margin-bottom:18px;border:1px solid #dcdfe6;border-left:0px">
                <tr>
                  <template v-for="(item,index) in communityDataMap">
                  <td class="item" :key="item.name">{{item.name}}</td>
                  <td class="item" :key="index">{{item.value}}</td>
                  </template>
                </tr>
              </table>
            </el-row>
            <el-form>
              <el-row>
                <el-col :span="9">
                  <el-form-item>
                    <el-cascader style="width: 99%" filterable v-model="communityTree" placeholder="请选择小区" :options="statisticsList.communityTreeData" :props="{multiple: true}" clearable collapse-tags></el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-select style="width: 98%" clearable filterable v-model="companyGuid" placeholder="请选择物业公司">
                      <el-option v-for="item in statisticsList.companyList" :label="item.name" :key="item.guid" :value="item.guid"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-select style="width: 98%" clearable filterable v-model="rectifyStatus" placeholder="请选择整改状态">
                      <el-option v-for="item in statisticsList.rectifyStatusList" :label="item" :key="item" :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                    <el-button type="primary" @click="goSearch">搜索</el-button>
                </el-col>
              </el-row>
            </el-form>
            <el-row>
              <el-table :data="statisticsCommunityList">
                <el-table-column prop="communityName" label="小区"></el-table-column>
                <el-table-column prop="streetName" label="街道"></el-table-column>
                <el-table-column prop="companyName" label="物业公司"></el-table-column>
                <el-table-column prop="rectifyStatus" label="整改状态"></el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="communityChange(scope.row.communityGuid)">详情</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-col>
          <el-col :span="9">
            <el-row :class="showPie">
              <div class="echart" id="echart-pie" :style="{width: '100%', height: '350px'}"></div>
            </el-row>
            <el-row :class="showPie">
              <div>{{community.communityName}}第{{currentPatrolTime}}次调查 {{operationType}}列表</div>
              <el-table :data="questionList" style="width: 100%">
                <el-table-column prop="questionTitle" label="检查项" width="300px"></el-table-column>
                <el-table-column prop="questionTypeName" label="类型" sortable></el-table-column>
                <el-table-column label="无此项" sortable>
                  <template slot-scope="scope">
                    {{scope.row.submitInvalid?'无此项':'-'}}
                  </template>
                </el-table-column>
                <template v-if="userInfo.role=='1'">
                  <el-table-column prop="submitScore" label="调查得分" ></el-table-column>
                </template>
                <template v-if="userInfo.role=='1'">
                  <el-table-column prop="resultScore" label="最终得分"></el-table-column>
                </template>
                <el-table-column fixed="right" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      @click="getDetail(scope.row)"
                      type="text"
                      size="mini"
                    > 查看统计
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-col>
        </el-row>
        <el-dialog title="信息" :visible.sync="dialogVisible" width="80%">
          <el-row>
            <el-col :span="24">
              <div class="table">
                <table style="width:100%" border="none">
                  <tr>
                    <td><el-tag>{{question.questionTypeName}}</el-tag></td>
                    <td colspan="7">{{question.questionTitle}}</td>
                  </tr>
                  <template v-if="userInfo.role == 1 || userInfo.role == 3">
                    <tr>
                    <td>评分标准</td>
                    <td colspan="7" v-html="question.questionStandardDesc">{</td>
                  </tr>
                  </template>
                  <tr>
                    <td v-if="userInfo.role == 1 || userInfo.role == 3" class="high">最终得分</td>
                    <td v-if="userInfo.role == 1 || userInfo.role == 3" class="high">{{question.resultScore}}</td>
                    <td class="high">检查人员备注</td>
                    <td colspan="3" class="high">{{question.submitRemark}}</td>
                  </tr>
                  <tr>
                    <td class="high">现场情况</td>
                    <td colspan="7" class="high">
                       <div v-for="image in question.imageUrlList" :key="image" class="imageOrVideo">
                          <el-image style="width: 200px; height: 200px" fit="cover" v-if="image.indexOf('.jpg')>-1 || image.indexOf('.png')>-1 || image.indexOf('.jpeg')>-1" :src="image" :preview-src-list="question.imageUrlList" :z-index="zIndex"></el-image>
                          <video v-else :src="image" controls="controls" style="width: 200px; height: 200px">您的浏览器不支持 video 标签</video>
                        </div>
                    </td>
                  </tr>
                  <tr v-if="userInfo.role == 1 || userInfo.role == 3">
                    <td>分值</td>
                    <td>{{question.questionScoreMin}} ~ {{question.questionScoreMax}}</td>
                    <td>检查人员打分</td>
                    <td>{{question.submitScore}}</td> 
                  </tr>
                  <tr v-if="userInfo.role == 1 || userInfo.role == 3">
                    <td>检查人员</td>
                    <td>{{question.submitStaffName}}</td>
                    <td>检查人员提交时间</td>
                    <td>{{question.submitHistoryTime}}</td>
                  </tr>
                </table>
              </div>
            </el-col>
          </el-row>
          <el-row>
              <el-col :span="24">
                <div v-if="operationType == '整改或复议'">
                  <div v-if="question.rectifyHistoryList">
                    <el-divider content-position="left">整改记录 ({{question.rectifyHistoryList.length}})</el-divider>
                    <div class="table" v-for="(_history,index) in question.rectifyHistoryList" :key="'_=_'+index">
                       <table style="width:100%">
                        <tr>
                          <td>状态</td>
                          <td>{{_history.statusStr}}</td>
                          <td>申请人</td>
                          <td>{{_history.createByName}}</td>
                          <td>申请时间</td>
                          <td>{{_history.createTime}}</td>
                        </tr>
                        <tr>
                          <td>申请说明</td>
                          <td colspan="7">{{_history.reason}}</td>
                        </tr>
                        <tr>
                          <td>现场情况</td>
                          <td colspan="7">
                            <div v-for="image in _history.imageUrlList" :key="image" class="imageOrVideo">
                                <el-image style="width: 200px; height: 200px" fit="cover" v-if="image.indexOf('.jpg')>-1 || image.indexOf('.png')>-1 || image.indexOf('.jpeg')>-1" :src="image" :preview-src-list="question.imageUrlList" :z-index="zIndex"></el-image>
                                <video v-else :src="image" controls="controls" style="width: 200px; height: 200px">您的浏览器不支持 video 标签</video>
                              </div>
                          </td>
                        </tr>
                        <tr v-if="_history.status !== '0'">
                          <td v-if="userInfo.role=='1'">得分</td>
                          <td v-if="userInfo.role=='1'">{{_history.score}}</td>
                          <td>审核说明</td>
                          <td :colspan="userInfo.role=='1'?3:5">{{_history.remark}}</td>
                        </tr>
                        <tr v-if="(_history.status == '0')||_history.statusStr == '待审核'">
                          <td v-if="userInfo.role=='1'">得分</td>
                          <td v-if="userInfo.role=='1'">
                            <el-input-number style="width:100%;" v-model="_history.score" placeholder="打分"></el-input-number>
                          </td>
                          <td>审核说明</td>
                          <td :colspan="userInfo.role=='1'?3:5">
                            <el-input v-model="_history.remark" placeholder="审核说明" :disabled="userInfo.role!='1'"></el-input>
                          </td>
                        </tr>
                       </table>
                    </div>
                  </div>
                  <div v-if="question.reconsiderHistoryList">
                    <el-divider content-position="left">复议记录 ({{question.reconsiderHistoryList.length}})</el-divider>
                      <div class="table" v-for="(_history,index) in question.reconsiderHistoryList" :key="'_==_'+index">
                       <table style="width:100%">
                        <tr>
                          <td>状态</td>
                          <td>{{_history.statusStr}}</td>
                          <td>申请人</td>
                          <td>{{_history.createByName}}</td>
                          <td>申请时间</td>
                          <td>{{_history.createTime}}</td>
                        </tr>
                        <tr>
                          <td>申请说明</td>
                          <td colspan="7">{{_history.reason}}</td>
                        </tr>
                        <tr>
                          <td>现场情况</td>
                          <td colspan="7">
                            <div v-for="image in _history.imageUrlList" :key="image" class="imageOrVideo">
                                <el-image style="width: 200px; height: 200px" fit="cover" v-if="image.indexOf('.jpg')>-1 || image.indexOf('.png')>-1 || image.indexOf('.jpeg')>-1" :src="image" :preview-src-list="question.imageUrlList" :z-index="zIndex"></el-image>
                                <video v-else :src="image" controls="controls" style="width: 200px; height: 200px">您的浏览器不支持 video 标签</video>
                              </div>
                          </td>
                        </tr>
                        <tr v-if="_history.status !== '0'">
                          <td v-if="userInfo.role=='1'">得分</td>
                          <td v-if="userInfo.role=='1'">{{_history.score}}</td>
                          <td>审核说明</td>
                          <td :colspan="userInfo.role=='1'?3:5">{{_history.remark}}</td>
                        </tr>
                        <tr v-if="_history.status == '0'">
                          <td v-if="userInfo.role=='1'">得分</td>
                          <td v-if="userInfo.role=='1'">
                            <el-input-number style="width:100%;" v-model="_history.score" placeholder="打分"></el-input-number>
                          </td>
                          <td>审核说明</td>
                          <td :colspan="userInfo.role=='1'?3:5">
                            <el-input v-model="_history.remark" placeholder="审核说明" :disabled="userInfo.role!='1'"></el-input>
                          </td>
                        </tr>
                       </table>
                    </div>
                  </div>
                </div>
              </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button type="default" @click="dialogVisible = false">关闭</el-button>
            <template v-if="userInfo.role=='1'">
              <el-button v-if="dialogVisible && (question.historyType.length > 0)" type="primary" @click="submitQuestion">提交</el-button>
            </template>
          </span>
        </el-dialog>
      </el-container>
    </div>
</template>
<script>
export default({
  data() {
    return {
      zIndex:9999,
      communityList: [],
      community: {
        communityGuid: "",
        communityName: "",
        patrolTimes: {},
      },
      showPie: "hidePie",
      currentPatrolTime: 0,
      operationType: "",
      questionList: [],
      question: {},
      dialogVisible: false,
      userInfo:{},
      statisticsList: {},
      communityTree: ['',''],
      companyGuid: '',
      rectifyStatus: '',
      patrolTime: '',
      statisticsCommunityList: [],
      communityTreeData: [],
      communityDataMap: []
    }
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getPatrolSurveyDetail();
  },
  methods: {
    getPatrolSurveyDetail() {
      let self = this;
      let projectGuid = self.$route.query.projectGuid;
      let surveyGuid = self.$route.query.surveyGuid;
      self.$axios
        .get("/patrol-survey/detail/report?projectGuid=" + projectGuid + "&surveyGuid=" + surveyGuid, {
          headers: { token: self.userInfo.token },
        }).then(function(response) {
          self.communityList = response.data.data;
          console.log(response.data.data);
          self.patrolTimeClick(Object.keys(self.communityList[0].patrolTimes)[0])
          self.community = self.communityList[0]
        }).catch(function(error) {
          self.$message({
            message: error.response.data.message,
            type: "warning",
          });
        })
    },
    communityChange(communityGuid) {
      let self = this;
      console.log('communityGuid=',communityGuid);
      self.community = self.$underscore.clone(
        self.$underscore.find(self.communityList, function(_el) {
          return _el.communityGuid == communityGuid;
        })
      );
      self.questionList = [];
      self.question = {};
      self.operationType = "";
      // self.showPie = 'hidePie';
      self.showPie = 'showPie';
      self.currentPatrolTime = self.patrolTime;
      // self.patrolTimeClick(Object.keys(self.community.patrolTimes)[0])
      self.operationType = Object.keys(self.community.patrolTimes[Object.keys(self.community.patrolTimes)[0]])[0];
      let questionList = self.$underscore.clone(self.community.patrolTimes[Object.keys(self.community.patrolTimes)[0]][self.operationType]);
      self.questionList = questionList;
      self.pieChart(self.patrolTime);
    },
    patrolTimeClick(patrolTime) {
      let self = this;
      self.patrolTime = patrolTime
      self.communityTreeData = []
      self.communityDataMap = []
      // self.showPie = 'showPie';
      // self.questionList = [];
      // self.question = {};
      // self.operationType = "";
      self.showPie = 'hidePie';
      self.currentPatrolTime = patrolTime;
      // self.pieChart(patrolTime);
      self.getStatistics(patrolTime)
      self.goSearch()
      self.statisticsCommunityList = []
    },
    pieChart(patrolTime) {
      let self = this;
      let chart = self.$echarts.init(document.getElementById('echart-pie'));
      let seriesValue = self.$underscore.map(self.community.patrolTimes[patrolTime], function(_value, _key) {
        return {name: _key, value: _value.length}
      });
      let option = {
        title: {
          text: '比例分布',
          left: 0,
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: 'horizontal',
          left: 0,
          bottom:0
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            data: seriesValue,
            emphasis: {
              itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      chart.clear();
      chart.setOption(option);
      chart.off("click");
      chart.on("click", function(_params) {
        console.log(_params);
        self.operationType = _params.name;
        let questionList = self.$underscore.clone(self.community.patrolTimes[patrolTime][_params.name]);
        self.questionList = questionList;
      })
    },
    getDetail(row) {
      let self = this;
      self.question = self.$underscore.clone(row);
      self.dialogVisible = true;
    },
    submitQuestion() {
      let self = this;
      let operationTypeStr = "";
      if (self.operationType != '整改或复议') {
        self.$message({
          message: "非法操作",
          type: "danger",
        });
        return false;
      }
      operationTypeStr = self.question.historyType;
      let _history = self.$underscore.clone(self.question[operationTypeStr+"HistoryList"][0]);
      let data = {
        guid: _history.guid,
        remark: _history.remark,
        score: _history.score
      }
      self.$axios
        .post("/" + operationTypeStr + "-history/handle", JSON.stringify(data), {
          headers: {
            "content-type": "application/json",
            token: self.userInfo.token,
          },
        })
        .then(function(response) {
          self.$message({
            message: "操作成功",
            type: "success",
          });
          self.question[operationTypeStr+"HistoryList"][0] = response.data.data;
          self.question.historyType = "";
          let hh = self.community.patrolTimes[self.currentPatrolTime][self.operationType];
          let questionIndex = null;
          self.$underscore.each(hh, function(_el, _index) {
            if (_el.questionGuid == self.question.questionGuid) {
              questionIndex = _index;
            }
          });
          self.community.patrolTimes[self.currentPatrolTime][self.operationType][questionIndex] = self.question;
          self.questionList = self.$underscore.clone(self.community.patrolTimes[self.currentPatrolTime][self.operationType]);
          let communityIndex = null;
          self.$underscore.each(self.communityList, function(_el, _index) {
            if (_el.communityGuid == self.community.communityGuid) {
              communityIndex = _index;
            }
          });
          self.communityList[communityIndex] = self.community;
          self.dialogVisible = false;
        })
        .catch( (error) => {
          self.$message({
            message: error.response.data.message,
            type: "warning",
          });
        });
    },
    // 获取统计数据
    getStatistics(patrolTime) {
      let self = this
      console.log(patrolTime);
      let projectGuid = self.$route.query.projectGuid
      let patrolSurveyGuid = self.$route.query.surveyGuid;
      self.$axios.get("/patrol-survey/community/data?patrolTimes="+patrolTime+"&projectGuid="+projectGuid+"&patrolSurveyGuid="+patrolSurveyGuid,{
        headers: { token: self.userInfo.token }
      })
      .then(function(res) {
        console.log(res.data.data);
        self.getTreeData(res.data.data.communityTreeData)
        self.statisticsList = res.data.data
        let mapData = {}
        for(let k in self.statisticsList.communityDataMap) {
          mapData = {
            name: k,
            value: res.data.data.communityDataMap[k]
          }
          self.communityDataMap.push(mapData)
        }
      })
      .catch(function(err) {
        console.log(err.message);
        self.$message({
          type: "warning",
          message: "单次小区数据统计获取失败:" + err.message
        })
      })
    },
    getTreeData(data){
      for(var i=0;i<data.length;i++){
        if(data[i].children==undefined || data[i].children.length==0){
          data[i].children=undefined;
        }else {
          this.getTreeData(data[i].children);
        }
      }
      return data;
    },
    // 搜索
    goSearch() {
      let self = this
      let projectGuid = self.$route.query.projectGuid
      let patrolSurveyGuid = self.$route.query.surveyGuid;
      let communityList = []
      
      self.communityTree.forEach(item => {
        if(item) {
          communityList.push(item[1])  
        }
      })
      let data = {
        companyGuid: self.companyGuid,
        patrolTimes: self.patrolTime,
        rectifyStatus: self.rectifyStatus,
        projectGuid: projectGuid,
        patrolSurveyGuid: patrolSurveyGuid,
        communityGuidArray: communityList
      }
      self.$axios.post('/patrol-survey/community/list/rectify',data,{
        headers: { token: self.userInfo.token }
      })
      .then(function(res) {
        console.log(res.data.data);
        self.statisticsCommunityList = res.data.data
      })
      .catch(function(err) {
        self.$message({
          type: 'warning',
          message: '搜索失败:' + err.message
        })
      })
    }
  }
})
</script>
<style lang="scss">
  #report {
    .item {
      background-color:hsl(0, 0%, 100%);
      border-width:0px 0px 1px;
      color:hsl(220, 4%, 58%);
      display:table-cell;
      font-size:14px;
      font-weight:700;
      gap:normal;
      padding:12px 0px;
      text-align:center;
      // background: #fafafa;
      border-left: 1px solid #dcdfe6;
    }
    .formItem {
      display: inline-block;
      vertical-align: top;
      border: 1px solid;
      border-left: 0px;
      margin-right: 0px;
      .el-form-item__label {
        border-right: 1px solid;
        background-color: #deeaf6;
      }
      .formSpan {
        display: inline-block;
        vertical-align: top;
        width: 30px;
        text-align: center
      }
    }
    .hidePie {
      visibility: hidden;
    }
    .showPie {
      visibility: visible;
    }
    .history-info{
      margin-top: 10px;
    }
    .history-info span:first-of-type{
      text-align: left;
      display: block;
      font-weight: bold;
    }
    .history-info span:last-of-type{
      text-align: left;
      display: block;
      color: #333;
    }
    .table{
      margin-bottom: 10px;
    }
    .table table{
      border-right: 1px solid #EBEEF5;
      border-bottom: 1px solid #EBEEF5;
      border-collapse: collapse;
    }
    .table th,.table td{
      border-left: 1px solid #EBEEF5;
      border-top: 1px solid #EBEEF5;
      padding: 10px;
    }
    .high {
      background-color: #ecf5ff;
      color: #52a7ff;
      border-left: 1px solid #dcedff !important;
      border-top: 1px solid #dcedff !important;
    }
  }
  .imageOrVideo{
    display: inline-block;
    width: 25%;
    height: 180px;
    padding: 10px
  }
  .imageOrVideo .el-image,.imageOrVideo video{
    width: 100%;
    height: 100%;
    display: inline-block;
  }
</style>
